body {
    overflow-x: hidden;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.mapOptions {
    padding: 3px 4px 5px;
    cursor: pointer;
    background-color: rgb(35, 31, 41);
    border-radius: 20px;
    height: 26px;
    width: 26px;
    background-size: initial;
    background-repeat: no-repeat;
    background-position: center center;
}

.MuiFormHelperText-root.Mui-error {
    text-transform: capitalize;
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 0px;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(20px, -1px) scale(0.75) !important;
}

.MuiInputBase-input::-webkit-outer-spin-button,
.MuiInputBase-input::-webkit-inner-spin-button {
    margin: 0;
}

/* #view-default-view div {
  z-index: 0 !important;
} */

.mapboxgl-popup-content {
    padding: 0px !important;
    border-radius: 12px !important;
    background: #312e3a !important;
}
.mapboxgl-popup-tip {
    border-right-color: '#231f298 !important';
}
.mapboxgl-popup {
    left: 2% !important;
    top: 2% !important;
}
.mapboxgl-ctrl-group button {
    background-color: #332d3c !important;
    background: #332d3c !important;
    padding: 0px !important;
    min-width: 20px !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: #312e3a !important;
}

.mapboxgl-popup {
    z-index: 1000000;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: #fff !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
    .MuiTableSortLabel-icon {
    color: #fff !important;
}
.MuiTableSortLabel-root:hover {
    color: #fff !important;
}
#deckgl-wrapper {
    background-color: #312e3a;
}

.pulse {
    /* Chrome, Safari, Opera */
    -webkit-animation: pulse 1s infinite;
    /* Internet Explorer */
    -ms-animation: pulse 1s infinite;
    /* Standard Syntax */
    animation: pulse 1.25s infinite;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes pulse {
    0% {
        color: rgb(190, 190, 190);
    }
    110% {
        color: black;
    }
}

/* Internet Explorer */
@-ms-keyframes pulse {
    0% {
        color: rgb(190, 190, 190);
    }
    110% {
        color: black;
    }
}

/* Standard Syntax */
@keyframes pulse {
    0% {
        color: rgb(190, 190, 190);
    }
    110% {
        color: black;
    }
}
.MuiDialog-paperWidthSm {
    width: 460px !important;
}
.flex-center {
    display: flex;
    align-items: center;
}

.MuiAccordionSummary-content {
    display: flex;
    align-items: center;
}
.MuiAccordionSummary-root {
    min-height: 40px;
    max-height: 40px;
}
.MuiAccordionSummary-root.Mui-expanded {
    min-height: 40px;
    max-height: 40px;
}
.MuiAccordion-root {
    min-height: 40px !important;
    max-height: 40px !important;
    color: #929096 !important;
}
.MuiAccordion-root.Mui-expanded {
    margin: 0;
}

.MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0 !important;
    /* color: #fff !important; */
}
.MuiAccordionSummary-expandIcon {
    color: #929096 !important;
}
.MuiAccordionSummary-expandIcon.Mui-expanded {
    color: #fff !important;
}
.MuiAccordionSummary-content .MuiTypography-root {
    font-size: 14px;
    padding-top: 2px;
}
div {
    box-shadow: none !important;
}
a {
    text-decoration: none !important;
}

/* global scrollbar */

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Replace with lightmode colors after we retire all dark mode views */
::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    min-height: 60px;
    min-width: 60px;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #808080;
}
/* Track */
::-webkit-scrollbar-track {
    border-radius: 10px;
}

/*removes the mapbox default message on bottom right*/
.mapboxgl-ctrl-bottom-right {
    display: none;
}

.flatfile_iFrameContainer {
    height: 100% !important;
} 

.flatfile_iframe-wrapper.flatfile_displayAsModal {
    padding-left: 100px !important;
}