body {
    margin: 0;
    font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'IBM Plex Sans', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* width */
::-webkit-scrollbar {
    width: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #47424f;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #47424f;
    border-radius: 4px;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    left: -6px;
    top: -6px;
}

.mapboxgl-popup-close-button {
    display: none !important;
}

.MuiCheckbox-root:hover {
    background-color: rgba(0, 0, 0, 0);
}
